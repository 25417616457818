.root {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    background: url("./bg.png") #fff no-repeat no-repeat left bottom;
    background-size: 100% auto;
}

.left {
    flex: 1;
    margin-right: 50px;
    height: 100%;
    background: url("./404.png") no-repeat right center;
    background-size: 50% auto;
}

.right {
    flex: 1;
    margin-left: 50px;
}

.right-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
}

.code {
    font-size: 100px;
    font-weight: bolder;
}

.message {
    font-size: 18px;
    margin-bottom: 60px;
}

.buttons {
    button {
        margin-right: 10px;
    }
}


@primary-color: #3b5f80;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 12px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;